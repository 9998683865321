import {
	getModule,
	Module,
	VuexModule,
	Mutation,
	Action,
} from 'vuex-module-decorators';
import store from '@/store';
import {
	UpdateGdbSystemSubmission, CardLoadProgress, GdbLoadProgress, ValueText,
	CustomerSupplierDbCard, AgentDbCard, StockDbCard, FilterDbSubmission, ProjectDbCard, AccountDbCard, LocationDbCard,
} from '@/store/models.def';
import moment, { Moment } from 'moment';
import { Dictionary } from 'vue-router/types/router';

@Module({
	namespaced: true,
	name: 'gdbx',
	store,
	dynamic: true,
})
class GobiDatabaseModule extends VuexModule {

	public versionIncompatible: boolean = false;
	public cardLoadProgress: CardLoadProgress = {};

	public numDecimal: number = 3;
	public currencySymbol: string = 'MYR';
	public openingStockAmount: number = 0;
	public accountStartingDate: Moment = moment().startOf('year');

	protected _customerDbs: CustomerSupplierDbCard[] = [];
	protected _supplierDbs: CustomerSupplierDbCard[] = [];
	protected _agentDbs: AgentDbCard[] = [];
	protected _stockDbs: StockDbCard[] = [];
	protected _projectDbs: ProjectDbCard[] = [];
	protected _locationDbs: LocationDbCard[] = [];
	protected _accountDbs: AccountDbCard[] = [];

	public get loadProgress(): GdbLoadProgress {

		const progresses = this.cardLoadProgress;
		const values = Object.values(progresses) as GdbLoadProgress[];

		const hasLoaded = values.findIndex((v) => typeof (v) === 'number') !== -1;
		if (hasLoaded) {
			const loadeds = (values.filter((v) => typeof (v) === 'number')) as number[];
			const loaded = loadeds.reduce((sum, v) => sum + (v as number), 0);
			return loaded;
		}

		if (values.indexOf('Loading') !== -1) { return 'Loading'; }
		if (values.indexOf('Processing') !== -1) { return 'Processing'; }
		if (values.indexOf('Completed') !== -1) { return 'Completed'; }
		return 'Idle';
	}


	public get accountStartingDateThisYear() {
		const startDate = this.accountStartingDate.clone().year(moment().year()).valueOf();
		return startDate;
	}

	public get financialPeriodThisYear(): [number, number] {
		if (
			moment().startOf('day').valueOf() >= this.accountStartingDateThisYear
		) {
			return [this.accountStartingDateThisYear,
			moment(this.accountStartingDateThisYear).add(1, 'year').add(-1, 'day').endOf('day').valueOf()];
		} else {
			return [moment(this.accountStartingDateThisYear)
				.add(-1, 'year')
				.startOf('day')
				.valueOf(), moment(this.accountStartingDateThisYear)
					.add(-1, 'day')
					.endOf('day')
					.valueOf()];
		}
	}

	public get agentNames(): Dictionary<string> {

		const dic: Dictionary<string> = {};
		for (const vt of this.allAgents) {
			dic[vt.value] = vt.text;
		}
		return Object.freeze(dic);
	}

	public get allAgents(): Array<ValueText<string>> {
		const dic: Dictionary<string> = {};
		if (this._agentDbs.length > 0) {
			for (const card of this._agentDbs) {
				if (card.agent) { dic[card.agent] = card.agentDesc || card.agent; }
			}
		}
		return generateSortedValueTextListFromNameDic(dic);
	}

	public get allAgentsCodeOnly(): Array<ValueText<string>> {
		const dic: Dictionary<string> = {};
		if (this._agentDbs.length > 0) {
			for (const card of this._agentDbs) {
				if (card.agent) { dic[card.agent] = card.agent; }
			}
		}
		return generateSortedValueTextListFromNameDic(dic);
	}

	public get allAreas(): Array<ValueText<string>> {
		const dic: Dictionary<string> = {};
		if (this._customerDbs.length > 0) {
			for (const card of this._customerDbs) {
				if (card.area) { dic[card.area] = card.areaDesc || card.area; }
			}
		}
		return generateSortedValueTextListFromNameDic(dic);
	}

	public get allLocations(): Array<ValueText<string>> {
		const dic: Dictionary<string> = {};
		if (this._locationDbs.length > 0) {
			for (const card of this._locationDbs) {
				if (card.code) { dic[card.code] = card.description || card.code; }
			}
		}
		return generateSortedValueTextListFromNameDic(dic);
	}

	public get allUOM(): Array<ValueText<string>> {
		const dic: Dictionary<string> = {};
		if (this._stockDbs.length > 0) {
			for (const card of this._stockDbs) {
				if (card.itemUOM) { dic[card.itemUOM] = card.itemUOM; }
			}
		}
		return generateSortedValueTextListFromValueDic(dic);
	}

	public get stockGroupNames(): Dictionary<string> {
		const dic: Dictionary<string> = {};
		if (this._stockDbs.length > 0) {

			for (const card of this._stockDbs) {
				if (card.stockGroup) { dic[card.stockGroup] = card.stockGroupDesc || card.stockGroup; }
			}

		}
		return renameDuplicates(dic);
	}
	public get allStockGroups(): Array<ValueText<string[]>> {

		const dic: Dictionary<string[]> = {};
		const pushItemCode = (card: { itemCode: string, stockGroup: string }) => {
			const stockGroupDesc = this.stockGroupNames[card.stockGroup];
			if (stockGroupDesc) {
				if (!dic[stockGroupDesc]) { dic[stockGroupDesc] = []; }
				dic[stockGroupDesc].push(card.itemCode);
			}
		};

		// extract stockGroup with array of stockItems
		if (this._stockDbs.length > 0) {
			this._stockDbs.forEach(pushItemCode);
		}

		return generateSortedValueTextListFromValueDic(dic);
	}

	public get stockItemNames(): Dictionary<string> {

		const dic: Dictionary<string> = {};
		if (this._stockDbs.length > 0) {

			for (const card of this._stockDbs) {
				if (card.itemCode) { dic[card.itemCode] = card.itemDesc || card.itemCode; }
			}

		}
		return renameDuplicates(dic);
	}
	public get allStockItems(): Array<ValueText<string>> {
		return generateSortedValueTextListFromNameDic(this.stockItemNames);
	}

	public get customerCategoryNames(): Dictionary<string> {
		const dic: Dictionary<string> = {};
		if (this._customerDbs) {
			for (const card of this._customerDbs) {
				if (card.companyCategory) { dic[card.companyCategory] = card.companyCategoryDesc || card.companyCategory; }
			}
		}
		return renameDuplicates(dic);
	}
	public get allCustomerCategories(): Array<ValueText<string[]>> {
		if (!this._customerDbs) { return []; }

		// extract companyCategories
		const dic: Dictionary<string[]> = {};
		for (const card of this._customerDbs) {
			if (card.companyCategory) {
				const companyCategoryDesc = this.customerCategoryNames[card.companyCategory];
				if (!dic[companyCategoryDesc]) { dic[companyCategoryDesc] = []; }
				dic[companyCategoryDesc].push(card.code);
			}
		}
		return generateSortedValueTextListFromValueDic(dic);
	}

	public get customerNames(): Dictionary<string> {

		const dic: Dictionary<string> = {};
		if (this._customerDbs) {
			for (const card of this._customerDbs) {
				if (card.code) { dic[card.code] = card.companyName || card.code; }
			}
		}
		return renameDuplicates(dic);
	}

	public get allCustomers(): Array<ValueText<string>> {
		return generateSortedValueTextListFromNameDic(this.customerNames);
	}

	public get areaNames(): Dictionary<string> {

		const dic: Dictionary<string> = {};
		if (this._customerDbs) {
			for (const card of this._customerDbs) {
				if (card.area) { dic[card.area] = card.areaDesc || card.area; }
			}
		}
		return renameDuplicates(dic);
	}

	// public get allAreas(): Array<ValueText<string>> {
	// 	return generateSortedValueTextListFromNameDic(this.areaNames);
	// }

	public get supplierCategoryNames(): Dictionary<string> {
		const dic: Dictionary<string> = {};
		if (this._supplierDbs) {
			for (const card of this._supplierDbs) {
				if (card.companyCategory) { dic[card.companyCategory] = card.companyCategoryDesc || card.companyCategory; }
			}
		}
		return renameDuplicates(dic);
	}
	public get allSupplierCategories(): Array<ValueText<string[]>> {
		if (!this._supplierDbs) { return []; }

		// extract companyCategories
		const dic: Dictionary<string[]> = {};
		for (const card of this._supplierDbs) {
			if (card.companyCategory) {
				const companyCategoryDesc = this.supplierCategoryNames[card.companyCategory];
				if (!dic[companyCategoryDesc]) { dic[companyCategoryDesc] = []; }
				dic[companyCategoryDesc].push(card.code);
			}
		}
		return generateSortedValueTextListFromValueDic(dic);
	}

	public get supplierNames(): Dictionary<string> {

		const dic: Dictionary<string> = {};
		if (this._supplierDbs) {
			for (const card of this._supplierDbs) {
				if (card.code) { dic[card.code] = card.companyName || card.code; }
			}
		}
		return renameDuplicates(dic);
	}
	public get allSuppliers(): Array<ValueText<string>> {
		return generateSortedValueTextListFromNameDic(this.supplierNames);
	}

	public get projectNames(): Dictionary<string> {
		const dic: Dictionary<string> = {};
		if (this._projectDbs) {
			for (const card of this._projectDbs) {
				if (card.code) {
					console.log(card.isActive);
					dic[card.code] =
						card.projectDesc + (card.isActive !== 'True' ? '_F' : '')
						|| card.code + (card.isActive !== 'True' ? '_F' : ''); }
			}
		}
		return renameDuplicates(dic);
	}

	public get accountNames(): Dictionary<string> {
		const dic: Dictionary<string> = {};
		if (this._accountDbs) {
			for (const card of this._accountDbs) {
				if (card.code) { dic[card.code] = card.accountName || card.code; }
			}
		}
		return renameDuplicates(dic);
	}

	public get allProjects(): Array<ValueText<string>> {
		return generateSortedValueTextListFromNameDic(this.projectNames);
	}


	@Mutation
	public m_updateFilterDbs(data: FilterDbSubmission) {
		if (data.globalStocks) {
			this._stockDbs = Object.freeze(data.globalStocks) as any;
		}
		if (data.globalAgents) {
			this._agentDbs = Object.freeze(data.globalAgents) as any;
		}
		if (data.globalCustomers) {
			this._customerDbs = Object.freeze(data.globalCustomers) as any;
		}
		if (data.globalSuppliers) {
			this._supplierDbs = Object.freeze(data.globalSuppliers) as any;
		}
		if (data.globalProjects) {
			this._projectDbs = Object.freeze(data.globalProjects) as any;
		}
		if (data.globalLocations) {
			this._locationDbs = Object.freeze(data.globalLocations) as any;
		}
		if (data.globalAcc) {
			this._accountDbs = Object.freeze(data.globalAcc) as any;
		}
	}

	@Mutation
	public m_updateProperties(accountProperties: UpdateGdbSystemSubmission) {
		this.currencySymbol = accountProperties.currencySymbol || '';
		this.accountStartingDate = accountProperties.accountStartingDate || this.accountStartingDate;
		this.numDecimal = accountProperties.numDecimal || 2;
	}
	@Mutation
	public m_updateOpeningStockAmount(value: number) {
		this.openingStockAmount = value;
	}
	@Mutation
	public m_updateVersionIncompatible(value: boolean) {
		this.versionIncompatible = value;
	}
	@Mutation
	public m_updateCardLoadProgress(loadProgress: CardLoadProgress) {
		this.cardLoadProgress = { ...loadProgress };
	}
	@Action
	public updateProperties(accountProperties: UpdateGdbSystemSubmission) {
		this.m_updateProperties(accountProperties);
	}
	@Action
	public updateOpeningStockAmount(value: number) {
		this.m_updateOpeningStockAmount(value);
	}
	@Action
	public updateVersionIncompatible(value: boolean) {
		this.m_updateVersionIncompatible(value);
	}
	@Action
	public updateCardLoadProgress(loadProgress: CardLoadProgress) {
		this.m_updateCardLoadProgress(loadProgress);
	}
	@Action
	public updateFilterDbs(data: FilterDbSubmission) {
		this.m_updateFilterDbs(data);
	}

	/** Dispose all cache when changing account */
	@Action
	public async dispose() {
		// console.log('dispose gdbx');
		this.m_updateProperties({
			currencySymbol: 'MYR',
			accountStartingDate: moment().startOf('year'),
			numDecimal: 2,
		});
		this.m_updateCardLoadProgress({});
		this.m_updateOpeningStockAmount(0);
		this.m_updateVersionIncompatible(false);

		this.m_updateFilterDbs({
			globalAgents: [], globalStocks: [], globalCustomers: [], globalSuppliers: []
			, globalProjects: [], globalLocations: [], globalAcc: [],
		});
	}
}
export default getModule(GobiDatabaseModule);

function trimBoolean(string: string) {
	return string.replace(/_F$/, '');
}

function generateSortedValueTextListFromNameDic(dic: Dictionary<string>) {
	const list = Object.keys(dic).map((value) => (Object.freeze(
			{ value,
				text: Object.freeze(trimBoolean(dic[value])),
				disabled: dic[value].endsWith('_F') },
	) as ValueText));
	// list.sort((a, b) => a.text.localeCompare(b.text));
	return list;
}
function generateSortedValueTextListFromValueDic(dic: Dictionary<string | string[]>) {
	const list = Object.keys(dic).map((text) => (Object.freeze({ text, value: Object.freeze(dic[text]) }) as ValueText));
	list.sort((a, b) => a.text.localeCompare(b.text));
	return list;
}
function renameDuplicates(dic: Dictionary<string>) {
	const names: Dictionary<number> = {};
	for (const code in dic) {
		if (dic.hasOwnProperty(code)) {
			const name = dic[code];
			if (names[name] !== undefined) {
				const name2 = name + '(' + ++names[name] + ')';
				dic[code] = name2;
			} else {
				names[name] = 0;
			}
		}
	}
	return Object.freeze(dic);
}
